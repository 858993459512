import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import MoleculePickUpNotification from '../../../Molecules/MoleculePickupNotification.js'
import MoleculeDispatchOptions from '../../../Molecules/MoleculeDispatchOptions.js'
import MoleculeTextSummary from '../../../Molecules/MoleculeTextSummary.js'

import OrganismSummaryBookShipment from '../../../PageComponents/Summary/OrganismSummaryBookShipment.js'

import NavigationReviewAndDispatch from '../../../PageComponents/Navigation/Quote/NavigationReviewAndDispatch.js'

import AccordionComponentOrigin from '../../../AccordionComponents/BookShipmentOriginAccordion.js'
import AccordionComponentDestination from '../../../AccordionComponents/DestinationAccordion.js'
import AccordionComponentShipmentItems from '../../../AccordionComponents/ShipmentItemsAccordion.js'
import AccordionComponentCarrierInformation from '../../../AccordionComponents/CarrierInformationAccordion.js'

const GContentReviewAndDispatch = styled.div`

  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  margin: 0 auto;
  max-width: 1360px;
  background-color: #fff !important;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: 1fr 0.1fr;
  grid-template-columns: 1fr 0.5fr;
  grid-template-areas:
    "sg_add_items sg_summary"
    "sg_navigation_stepper sg_summary";

  .SGAddToShipment {
    grid-area: sg_add_items; // 🗺
  }
  .SGSummary {
    grid-area: sg_summary; // 🗺
  }
  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
    align-self: start;
  }
`;

const SGAddToShipment = styled.div`
  padding-right: 20px;
  margin-top: 20px;
`;
const SGSummary = styled.div`
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 20px;
`;
const SGNavigationStepper = styled.div`
`;
const PageTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin: 0 auto;
    max-width: 1360px;
`;





const GridComponentAddItems = () => {
  return (

    <GContentReviewAndDispatch>

        <SGAddToShipment>
            
            <MoleculePickUpNotification></MoleculePickUpNotification>
            <MoleculeDispatchOptions></MoleculeDispatchOptions>
            <MoleculeTextSummary></MoleculeTextSummary>

            <PageTitle>Origin</PageTitle>
            <AccordionComponentOrigin></AccordionComponentOrigin>
            <PageTitle>Destination</PageTitle>
            <AccordionComponentDestination></AccordionComponentDestination>
            <PageTitle>Shipment Items (1)</PageTitle>
            <AccordionComponentShipmentItems></AccordionComponentShipmentItems>
            <PageTitle>Carrier Information</PageTitle>
            <AccordionComponentCarrierInformation></AccordionComponentCarrierInformation>

        </SGAddToShipment>


        <SGSummary>
            <OrganismSummaryBookShipment></OrganismSummaryBookShipment>
        </SGSummary>

        <SGNavigationStepper>
            <NavigationReviewAndDispatch></NavigationReviewAndDispatch>
        </SGNavigationStepper>

    </GContentReviewAndDispatch>

  )
}

export default GridComponentAddItems;