import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';
import '../styles/icons/css/icons.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'

library.add(faChevronCircleDown)

const AccordionComponentCarrierInformation = styled.div`
.es-c-accordion--carrier-info {
  // 🎛
  // 🎨

  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
  }
  // 🖋
  font-family: 'Open Sans';
    .es-accordion-wrapper {
    // 📰
    display: grid;
    grid-template-rows: 0.2fr 0.1fr;
    grid-template-columns: 1fr 1fr 0.3fr;
    grid-template-areas:
      "accordion_header"
      "accordion_body";

    .accordion-header {
      grid-area: accordion_header; // 🗺
      background-color: #505C61;
      color: #fff;
      border-radius: 3px 3px 0px 0px;
      // 💅
      transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
      
      .es-accordion-wrapper--header {
        min-width: 800px;
        min-height: 70px;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
        grid-template-areas:
          "sg_title sg_title sg_title sg_city sg_carrot"
          "sg_subtitle sg_subtitle sg_subtitle sg_zip sg_carrot"; 
        

        .es-sg-carrot {
          grid-area: sg_carrot; // 🗺
          align-self: center;
          justify-self: center;
          padding-left: 40px;
        }
        .es-sg-title {
          grid-area: sg_title; // 🗺
          padding-left: 18px;
          align-self: center;
          line-height: 24px;
          font-size: 18px;
        }
        .es-sg-subtitle {
          grid-area: sg_subtitle; // 🗺
          padding-left: 18px;
          align-self: center;
          font-size: 12px;
          font-weight: 500;
        }
      }  
    }
    .accordion-body {
      grid-area: accordion_body; // 🗺
      background: #FFFFFF;
      border: 1px solid #BBBBBB;
      border-radius: 0px 0px 3px 3px;
      .es-accordion-wrapper--body {
        padding: 20px;
      }
    }
  }
}

`;

const AccordionComponent = () => {
  return (
    <AccordionComponentCarrierInformation>
  <div className="es-c-accordion--carrier-info">
        <div className="es-accordion-wrapper">
          <div className="accordion">
            <input
              type="checkbox"
              id="accordion-4"
              name="accordion-checkbox"
              hidden
            />
            
            <label className="accordion-header" htmlFor="accordion-4">
              
              <div className="es-accordion-wrapper--header">
                <div className="es-sg-carrot">
                <i class="fas fa-copy"></i>
                </div>
                <div className="es-sg-title">FedEx Economy</div>
                <div className="es-sg-subtitle">Carrier</div>
              </div>
            </label>
            
            <div className="accordion-body">
              
              <div className="es-accordion-wrapper--body">
                
                <div className="es-c-expand-rate-result">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Terminals
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        Liability
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col">
                            <p className="es-t-title">Origin Terminal</p>
                            <p className="es-t-info">
                              <strong>CHICAGO - IL</strong>
                              <br />
                              200 NORTH MANNHEIM
                              <br />
                              HILLSIDE, IL 60162
                              <br />
                              586-467-1900
                            </p>
                          </div>
                          <div className="col">
                            <p className="es-t-title">Destination Terminal</p>
                            <p className="es-t-info">
                              <strong>TOMAH - WI</strong>
                              <br />
                              403 HOLLAND STREET
                              <br />
                              TOMAH, WI 54660
                              <br />
                              586-467-1900
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      ...
                    </div>
                    <div
                      className="tab-pane fade"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      ...
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
  

    </AccordionComponentCarrierInformation>
  )
}

export default AccordionComponent;